<template>
  <v-menu
    v-model="showMenu"
    offset-y
    max-width="324px"
    max-height="400px"
    :close-on-content-click="false"
    auto
    eager
  >
    <template v-slot:activator="{ on }">
      <div v-on="on" class="cursor-pointer">
        <slot v-if="!!$slots.default"></slot>
        <v-icon v-else>fal fa-smile</v-icon>
      </div>
    </template>

    <v-container class="white pt-1 pb-1" fluid grid-list-md>
      <v-layout>
        <v-flex
          v-for="(category, index) in emojiCategories"
          :key="index"
          class="emoji-icon grey-emoji title cursor-pointer text-center"
          :class="{
            'grey lighten-3':
              selectedCategory &&
              selectedCategory.category === category.category
          }"
          @click="selectedCategory = category"
        >
          <a :title="category.category">
            {{ category.icon }}
          </a>
        </v-flex>
      </v-layout>

      <v-divider class="mt-1 mb-1"></v-divider>

      <v-layout v-for="(emojiRow, indexRow) in orderedEmojis" :key="indexRow">
        <v-flex
          v-for="(emoji, emojiIndex) in emojiRow"
          :key="emojiIndex"
          class="emoji-icon title text-center"
          :class="{ 'cursor-pointer': emoji.name }"
          @click="selectEmoji(emoji)"
        >
          <a :title="emoji.name">
            {{ emoji.char }}
          </a>
        </v-flex>
      </v-layout>
    </v-container>
  </v-menu>
</template>

<script>
import EmojiList from "../../assets/EmojiList.json";

export default {
  props: {
    closeOnContentClick: {
      required: false,
      type: Boolean,
      default: false
    },
    returnObject: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    showMenu: false,
    selectedCategory: null
  }),
  computed: {
    emojis() {
      return EmojiList;
    },
    emojiCategories() {
      let emojisByCategory = [];

      this.emojis.forEach(emoji => {
        // Remove emojis
        if (["263A FE0F", "263A"].includes(emoji.codes)) {
          return;
        }

        let startParentheses = emoji.category.indexOf(" (");
        let category = emoji.category.substring(0, startParentheses);

        // Don't include categories
        if (category === "Component" || category === "Flags") {
          return;
        }

        // Check if category exists
        let categoryExists = emojisByCategory.filter(
          categoryTmp => categoryTmp.category === category
        );

        // // Add new category
        if (categoryExists.length === 0) {
          emojisByCategory.push({
            category: category,
            icon: emoji.char,
            emojis: []
          });
        }

        let categoryIndex = emojisByCategory.findIndex(
          categoryTmp => categoryTmp.category === category
        );
        emojisByCategory[categoryIndex].emojis.push(emoji);
      });

      return emojisByCategory;
    },
    orderedEmojis() {
      if (!this.selectedCategory) {
        return [];
      }

      let emojis = this.selectedCategory.emojis;
      let rowSize = this.emojiCategories.length;

      let emojiRows = new Array(Math.ceil(emojis.length / rowSize))
        .fill("")
        .map(function() {
          return this.splice(0, rowSize);
        }, emojis.slice());

      let lastRowIndex = emojiRows.length - 1;

      // If last row is not correct size add dummies
      if (emojiRows[lastRowIndex].length < rowSize) {
        while (emojiRows[lastRowIndex].length !== rowSize) {
          emojiRows[lastRowIndex].push({
            codes: "",
            char: "",
            category: "",
            name: ""
          });
        }
      }

      return emojiRows;
    }
  },
  watch: {
    showMenu() {
      if (this.showMenu) {
        this.$emit("opened", true);
      } else {
        this.$emit("closed", true);
      }
    }
  },
  methods: {
    selectEmoji(emoji) {
      this.$emit("emoji", this.returnObject ? emoji : emoji.char);

      if (this.closeOnContentClick) {
        this.showMenu = false;
      }
    }
  },
  mounted() {
    // Preselect category
    if (this.emojiCategories.length > 0) {
      this.selectedCategory = this.emojiCategories[0];
    }
  }
};
</script>

<style scoped>
.emoji-icon {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.emoji-icon:hover {
  background: #eeeeee;
}

.grey-emoji {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

/* width */
::-webkit-scrollbar {
  width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #bdbdbd;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #9e9e9e;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #9e9e9e;
}
</style>
