import { Extension } from "@tiptap/core";
import { Plugin, PluginKey, TextSelection } from "prosemirror-state";

export const EventHandler = Extension.create({
  name: "EventHandler",
  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey("EventHandler"),
        props: {
          // custom variable delete handler
          handleKeyDown(view, event) {
            if (event.code === "Backspace") {
              const { doc, tr } = view.state;
              const { $from, $anchor } = view.state.selection;
              const prevNode = $from.nodeBefore;
              const prevContent = tr.selection.$anchor.node(tr.selection.$anchor.depth).content.content;
              if (prevContent.length === 0) {
                return null;
              }
              const lastNodeType = prevContent[prevContent.length -1].type.name;
              if (prevNode && lastNodeType === "CustomVariable") {
                const $start = doc.resolve($anchor.pos - prevNode.nodeSize);
                const $end = doc.resolve($anchor.pos + 1);
                const transaction = tr.setSelection(new TextSelection($start, $end));
                view.dispatch(transaction);
              }
            }
          }
          // Here is the full list: https://prosemirror.net/docs/ref/#view.EditorProps
        }
      })
    ];
  }
});
