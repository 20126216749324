<template>
  <div class="tiptap-editor">
    <div class="editor-header">
      <div class="editor-label primary--text" v-if="label">
        <span class="mr-2">{{ label.text }}</span>
        <span class="cursor-pointer" v-if="label.tooltip">
          <v-tooltip :disabled="$vuetify.breakpoint.xsOnly" top>
            <template v-slot:activator="{ on }">
              <a :href="label.tooltip.link" target="_blank" class="no-decoration">
                <v-icon v-on="on" size="16px">{{ label.tooltip.icon }}</v-icon>
              </a>
            </template>
            <span>{{ label.tooltip.text }}</span>
          </v-tooltip>
        </span>
      </div>
      <v-spacer></v-spacer>
      <div class="text-center" v-if="customVariables.length > 0">
        <v-menu open-on-hover bottom offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn text color="accent" v-bind="attrs" v-on="on">
              Personalize
            </v-btn>
          </template>
          <v-list>
            <v-list-item
                v-for="(item, index) in customVariables"
                :key="index"
                @click="insertVariable(item.value)"
            >
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>
    <div v-if="editor && !hideToolbar">
      <div class="editor-toolbar p-2">
        <div class="d-flex" v-if="!emojiOnly">
          <!-- undo -->
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <button
                  class="menubar__button"
                  @click.prevent="editor.chain().focus().undo().run()"
                  v-bind="attrs"
                  v-on="on"
              >
                <v-icon :color="iconsColor" :size="iconsHeight"
                >fal fa-undo</v-icon
                >
              </button>
            </template>
            <span>Undo</span>
          </v-tooltip>
          <!-- redo -->
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <button
                  class="menubar__button"
                  @click.prevent="editor.chain().focus().redo().run()"
                  v-bind="attrs"
                  v-on="on"
              >
                <v-icon :color="iconsColor" :size="iconsHeight"
                >fal fa-redo</v-icon
                >
              </button>
            </template>
            <span>Redo</span>
          </v-tooltip>
          <!-- bold -->
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <button
                  class="menubar__button"
                  :class="{ 'is-active': editor.isActive('bold') }"
                  @click.prevent="editor.chain().focus().toggleBold().run()"
                  v-bind="attrs"
                  v-on="on"
              >
                <v-icon :color="iconsColor" :size="iconsHeight"
                >fal fa-bold</v-icon
                >
              </button>
            </template>
            <span>Bold</span>
          </v-tooltip>
          <!-- Italic -->
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <button
                  class="menubar__button"
                  :class="{ 'is-active': editor.isActive('italic') }"
                  @click.prevent="editor.chain().focus().toggleItalic().run()"
                  v-bind="attrs"
                  v-on="on"
              >
                <v-icon :color="iconsColor" :size="iconsHeight"
                >fal fa-italic</v-icon
                >
              </button>
            </template>
            <span>Italic</span>
          </v-tooltip>
          <!-- underline -->
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <button
                  class="menubar__button"
                  :class="{ 'is-active': editor.isActive('underline') }"
                  @click.prevent="editor.chain().focus().toggleUnderline().run()"
                  v-bind="attrs"
                  v-on="on"
              >
                <v-icon :color="iconsColor" :size="iconsHeight"
                >fal fa-underline</v-icon
                >
              </button>
            </template>
            <span>Underline</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <button
                  class="menubar__button"
                  :class="{ 'is-active': editor.isActive('link') }"
                  @click.prevent="setUrl"
                  :disabled="textSelection.isCollapsed"
                  v-bind="attrs"
                  v-on="on"
              >
                <v-icon :color="iconsColor" :size="iconsHeight"
                >fal fa-link</v-icon
                >
              </button>
            </template>
            <span>Link</span>
          </v-tooltip>
          <!-- Bullet list -->
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <button
                  class="menubar__button"
                  :class="{ 'is-active': editor.isActive('bulletList') }"
                  @click.prevent="editor.chain().focus().toggleBulletList().run()"
                  v-bind="attrs"
                  v-on="on"
              >
                <v-icon :color="iconsColor" :size="iconsHeight"
                >fal fa-list-ul</v-icon
                >
              </button>
            </template>
            <span>Bullet list</span>
          </v-tooltip>
          <!-- Ordered list -->
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <button
                  class="menubar__button"
                  :class="{ 'is-active': editor.isActive('orderedList') }"
                  @click.prevent="editor.chain().focus().toggleOrderedList().run()"
                  v-bind="attrs"
                  v-on="on"
              >
                <v-icon :color="iconsColor" :size="iconsHeight"
                >fal fa-list-ol</v-icon
                >
              </button>
            </template>
            <span>Ordered list</span>
          </v-tooltip>
          <!-- Blockquote -->
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <button
                  class="menubar__button"
                  :class="{ 'is-active': editor.isActive('blockquote') }"
                  @click.prevent="editor.chain().focus().toggleBlockquote().run()"
                  v-bind="attrs"
                  v-on="on"
              >
                <v-icon :color="iconsColor" :size="iconsHeight"
                >fal fa-quote-right</v-icon
                >
              </button>
            </template>
            <span>Blockquote</span>
          </v-tooltip>
        </div>
        <!-- Emoji -->
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div
                class="d-flex align-center justify-center menubar__button"
                v-bind="attrs"
                v-on="on"
            >
              <emoji-picker
                  v-if="$vuetify.breakpoint.smAndUp"
                  close-on-content-click
                  @closed="focus"
                  @emoji="insertEmoji($event)"
              ></emoji-picker>
            </div>
          </template>
          <span>Emoji</span>
        </v-tooltip>
      </div>
    </div>
    <div class="editor__content p-2" @click.stop="focus">
      <editor-content :editor="editor" ref="editorContent"/>
    </div>
    <div class="px-2 py-1 caption editor-helper-text">
      <div :class="textStrengthIndicationClass">
        {{ valueLength }} / {{ maxlength }} <span v-if="hasTextStrength" class="ml-2">{{ textStrengthIndicationText }}</span>
      </div>
    </div>
    <v-dialog max-width="600px" v-model="showLinkDialog">
      <v-card>
        <v-card-title>
          <div class="headline">Insert link</div>
        </v-card-title>
        <v-card-text>
          <v-form @submit.prevent="submitModal()">
            <v-text-field v-model="linkUrl" label="Link"></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeModal()">Anullér</v-btn>
          <v-btn color="primary" @click="submitModal()">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { Editor, EditorContent } from "@tiptap/vue-2";
import { defaultExtensions } from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import Link from "@tiptap/extension-link";
import { CustomVariable } from "@/helpers/extensions/CustomVariable.js";
import { EventHandler } from "@/helpers/extensions/EventHandler.js";
import Placeholder from "@tiptap/extension-placeholder";
import EmojiPicker from "../pickers/EmojiPicker";

export default {
  components: {
    EditorContent,
    EmojiPicker
  },
  props: {
    value: {
      required: true
    },
    label: {
      required: false,
      default: null
    },
    placeholder: {
      required: false,
      default: null
    },
    height: {
      required: false,
      default: 300
    },
    maxheight: {
      required: false,
      default: 400
    },
    minlength: {
      required: false,
      type: Number,
      default: 100
    },
    maxlength: {
      required: false,
      type: Number,
      default: 550
    },
    customVariables: {
      required: false,
      type: Array,
      default: () => []
    },
    hideToolbar: {
      required: false,
      type: Boolean,
      default: false
    },
    emojiOnly: {
      required: false,
      type: Boolean,
      default: false
    },
    returnOnlyText: {
      required: false,
      type: Boolean,
      default: false
    },
    textStrength: {
      required: false,
      type: Array, // Array of objects {min: int, max: int, class: string, text: string}
      default: () => ([])
    }
  },
  data: () => ({
    editor: null,
    editorValue: "",
    iconsHeight: "18px",
    iconsColor: "rgba(0,0,0,.54)",
    showLinkDialog: false,
    linkUrl: null,
    cursorPosition: 0
  }),
  computed: {
    valueWithoutTags() {
      let tmp = document.createElement("DIV");
      tmp.innerHTML = this.editorValue;
      tmp.innerHTML = tmp.innerHTML.replace(/(<\/p>)/g, " \\n </p>");

      let valueWithoutTags = (tmp.textContent || tmp.innerText || "").replace(/  +/g, " ");

      // Emit value so it can be used for validation
      this.$emit('valueWithoutTags', valueWithoutTags);

      return valueWithoutTags;
    },
    // This method calculates the "true length" of the input
    valueLength() {
      let count = 0;

      if (this.valueWithoutTags.length > 0) {
        count = this.valueWithoutTags.length - 4;
      }

      this.$emit('valueCount', count);
      return count;
    },
    textSelection() {
      return window.getSelection();
    },
    hasTextStrength(){
      return this.textStrength.length > 0;
    },
    textStrengthIndication() {
      let length = this.valueLength;

      for(let i = 0; i < this.textStrength.length; i++) {
        let indicator = this.textStrength[i];

        if(length >= indicator.min && length <= indicator.max) {
          return indicator;
        }
      }

      return null;
    },
    textStrengthIndicationClass() {
      return this.hasTextStrength && this.textStrengthIndication ? this.textStrengthIndication.class : "";
    },
    textStrengthIndicationText() {
      return this.hasTextStrength && this.textStrengthIndication ? this.textStrengthIndication.text : "";
    }
  },
  methods: {
    elementFromString(value) {
      const element = document.createElement("div");
      element.innerHTML = value.trim();
      return element;
    },
    handleVariable({ state, view }, value) {
      const { selection } = state;
      const element = this.elementFromString(value);
      const slice = DOMParser.fromSchema(state.schema).parseSlice(element);
      const transaction = state.tr.insert(selection.anchor, slice.content);
      view.dispatch(transaction);
    },
    focus() {
      if (!this.editor.view.focused) {
        this.editor.commands.focus();
      }
    },
    openLinkModal(url) {
      this.showLinkDialog = true;
      this.linkUrl = url;
    },
    clsoeLinkModal() {
      this.showLinkDialog = false;
      this.linkUrl = null;
      this.command = null;
    },
    submitModal() {
      this.editor
          .chain()
          .focus()
          .setLink({ href: this.linkUrl })
          .run();
      this.showLinkDialog = false;
    },
    insertEmoji(value) {
      this.editor.commands.insertContent(value);
    },
    insertVariable(value) {
      this.editor
          .chain()
          .focus()
          .insertContent(
              `<span>
          <custom-variable datavariable="true" class="custom-variable"}}>
            ${value}
          </custom-variable>
          </span>`
          )
          .run();
    },
    setUrl() {
      const state = this.editor.state;
      // get marks, if any from selected area
      const { from, to } = state.selection;
      let marks = [];
      state.doc.nodesBetween(from, to, node => {
        marks = [...marks, ...node.marks];
      });
      const mark = marks.find(markItem => markItem.type.name === "link");
      if (mark && mark.attrs.href) {
        const presetURL = mark.attrs.href;
        this.openLinkModal(presetURL);
      } else {
        this.openLinkModal("");
      }
    }
  },
  mounted() {
    const component = this;
    Placeholder.options.placeholder = this.placeholder;
    this.editor = new Editor({
      extensions: [
        ...defaultExtensions(),
        Underline,
        Link,
        Placeholder,
        CustomVariable,
        EventHandler
      ],
      onUpdate() {
        // set value
        component.editorValue = this.getHTML();
        // check return value
        const content = component.returnOnlyText ? component.valueWithoutTags : component.editorValue;
        component.$emit("input", content);
      }
    });
    this.$nextTick(() => {
      this.editorValue = this.value;
      this.editor.commands.setContent(this.editorValue);
      this.$refs.editorContent.$el.style.minHeight = `${this.height}px`;
      this.$refs.editorContent.$el.style.maxHeight = `${this.maxheight}px`;
    });
  },
  beforeDestroy() {
    this.editor.destroy();
  }
};
</script>
<style lang="scss">
.editor-header {
  display: flex;
  margin-bottom: 5px;
  .editor-label {
    display: flex;
    align-items: center;
  }
}
.editor-toolbar {
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-bottom: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  display: flex;
  .menubar__button {
    height: 30px;
    width: 30px;
    margin: 2px 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none !important;
    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
    }
    &.is-active {
      background-color: rgba(0, 0, 0, 0.15);
    }
    border-radius: 100%;
    &:disabled {
      opacity: 0.5;
    }
  }
}
.editor__content {
  border: 1px solid #e3e3e3;
  border-bottom: 0;
  outline: none !important;
  cursor: text;
  overflow: auto;
  .ProseMirror {
    outline: none !important;
  }
}
.editor-helper-text {
  margin-top: -1px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border: 1px solid #e3e3e3;
  border-top: 0;
}
/* Placeholder (at the top) */
.ProseMirror p.is-editor-empty:first-child::before {
  content: attr(data-placeholder);
  float: left;
  color: #ced4da;
  pointer-events: none;
  height: 0;
}
.ProseMirror custom-variable {
  color: #4CB7C4;
  background: #EDF8F9;
  padding: 3px 5px;
  border-radius: 5px;
}
</style>
