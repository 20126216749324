var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tiptap-editor"},[_c('div',{staticClass:"editor-header"},[(_vm.label)?_c('div',{staticClass:"editor-label primary--text"},[_c('span',{staticClass:"mr-2"},[_vm._v(_vm._s(_vm.label.text))]),(_vm.label.tooltip)?_c('span',{staticClass:"cursor-pointer"},[_c('v-tooltip',{attrs:{"disabled":_vm.$vuetify.breakpoint.xsOnly,"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('a',{staticClass:"no-decoration",attrs:{"href":_vm.label.tooltip.link,"target":"_blank"}},[_c('v-icon',_vm._g({attrs:{"size":"16px"}},on),[_vm._v(_vm._s(_vm.label.tooltip.icon))])],1)]}}],null,false,3505817030)},[_c('span',[_vm._v(_vm._s(_vm.label.tooltip.text))])])],1):_vm._e()]):_vm._e(),_c('v-spacer'),(_vm.customVariables.length > 0)?_c('div',{staticClass:"text-center"},[_c('v-menu',{attrs:{"open-on-hover":"","bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","color":"accent"}},'v-btn',attrs,false),on),[_vm._v(" Personalize ")])]}}],null,false,949741791)},[_c('v-list',_vm._l((_vm.customVariables),function(item,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.insertVariable(item.value)}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.text))])],1)}),1)],1)],1):_vm._e()],1),(_vm.editor && !_vm.hideToolbar)?_c('div',[_c('div',{staticClass:"editor-toolbar p-2"},[(!_vm.emojiOnly)?_c('div',{staticClass:"d-flex"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('button',_vm._g(_vm._b({staticClass:"menubar__button",on:{"click":function($event){$event.preventDefault();_vm.editor.chain().focus().undo().run()}}},'button',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.iconsColor,"size":_vm.iconsHeight}},[_vm._v("fal fa-undo")])],1)]}}],null,false,3663948932)},[_c('span',[_vm._v("Undo")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('button',_vm._g(_vm._b({staticClass:"menubar__button",on:{"click":function($event){$event.preventDefault();_vm.editor.chain().focus().redo().run()}}},'button',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.iconsColor,"size":_vm.iconsHeight}},[_vm._v("fal fa-redo")])],1)]}}],null,false,153138180)},[_c('span',[_vm._v("Redo")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('button',_vm._g(_vm._b({staticClass:"menubar__button",class:{ 'is-active': _vm.editor.isActive('bold') },on:{"click":function($event){$event.preventDefault();_vm.editor.chain().focus().toggleBold().run()}}},'button',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.iconsColor,"size":_vm.iconsHeight}},[_vm._v("fal fa-bold")])],1)]}}],null,false,73629620)},[_c('span',[_vm._v("Bold")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('button',_vm._g(_vm._b({staticClass:"menubar__button",class:{ 'is-active': _vm.editor.isActive('italic') },on:{"click":function($event){$event.preventDefault();_vm.editor.chain().focus().toggleItalic().run()}}},'button',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.iconsColor,"size":_vm.iconsHeight}},[_vm._v("fal fa-italic")])],1)]}}],null,false,563139563)},[_c('span',[_vm._v("Italic")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('button',_vm._g(_vm._b({staticClass:"menubar__button",class:{ 'is-active': _vm.editor.isActive('underline') },on:{"click":function($event){$event.preventDefault();_vm.editor.chain().focus().toggleUnderline().run()}}},'button',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.iconsColor,"size":_vm.iconsHeight}},[_vm._v("fal fa-underline")])],1)]}}],null,false,1992793367)},[_c('span',[_vm._v("Underline")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('button',_vm._g(_vm._b({staticClass:"menubar__button",class:{ 'is-active': _vm.editor.isActive('link') },attrs:{"disabled":_vm.textSelection.isCollapsed},on:{"click":function($event){$event.preventDefault();return _vm.setUrl($event)}}},'button',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.iconsColor,"size":_vm.iconsHeight}},[_vm._v("fal fa-link")])],1)]}}],null,false,2102264764)},[_c('span',[_vm._v("Link")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('button',_vm._g(_vm._b({staticClass:"menubar__button",class:{ 'is-active': _vm.editor.isActive('bulletList') },on:{"click":function($event){$event.preventDefault();_vm.editor.chain().focus().toggleBulletList().run()}}},'button',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.iconsColor,"size":_vm.iconsHeight}},[_vm._v("fal fa-list-ul")])],1)]}}],null,false,2147054631)},[_c('span',[_vm._v("Bullet list")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('button',_vm._g(_vm._b({staticClass:"menubar__button",class:{ 'is-active': _vm.editor.isActive('orderedList') },on:{"click":function($event){$event.preventDefault();_vm.editor.chain().focus().toggleOrderedList().run()}}},'button',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.iconsColor,"size":_vm.iconsHeight}},[_vm._v("fal fa-list-ol")])],1)]}}],null,false,2210531389)},[_c('span',[_vm._v("Ordered list")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('button',_vm._g(_vm._b({staticClass:"menubar__button",class:{ 'is-active': _vm.editor.isActive('blockquote') },on:{"click":function($event){$event.preventDefault();_vm.editor.chain().focus().toggleBlockquote().run()}}},'button',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.iconsColor,"size":_vm.iconsHeight}},[_vm._v("fal fa-quote-right")])],1)]}}],null,false,3265636806)},[_c('span',[_vm._v("Blockquote")])])],1):_vm._e(),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"d-flex align-center justify-center menubar__button"},'div',attrs,false),on),[(_vm.$vuetify.breakpoint.smAndUp)?_c('emoji-picker',{attrs:{"close-on-content-click":""},on:{"closed":_vm.focus,"emoji":function($event){return _vm.insertEmoji($event)}}}):_vm._e()],1)]}}],null,false,1279885130)},[_c('span',[_vm._v("Emoji")])])],1)]):_vm._e(),_c('div',{staticClass:"editor__content p-2",on:{"click":function($event){$event.stopPropagation();return _vm.focus($event)}}},[_c('editor-content',{ref:"editorContent",attrs:{"editor":_vm.editor}})],1),_c('div',{staticClass:"px-2 py-1 caption editor-helper-text"},[_c('div',{class:_vm.textStrengthIndicationClass},[_vm._v(" "+_vm._s(_vm.valueLength)+" / "+_vm._s(_vm.maxlength)+" "),(_vm.hasTextStrength)?_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.textStrengthIndicationText))]):_vm._e()])]),_c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.showLinkDialog),callback:function ($$v) {_vm.showLinkDialog=$$v},expression:"showLinkDialog"}},[_c('v-card',[_c('v-card-title',[_c('div',{staticClass:"headline"},[_vm._v("Insert link")])]),_c('v-card-text',[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitModal()}}},[_c('v-text-field',{attrs:{"label":"Link"},model:{value:(_vm.linkUrl),callback:function ($$v) {_vm.linkUrl=$$v},expression:"linkUrl"}})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.closeModal()}}},[_vm._v("Anullér")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.submitModal()}}},[_vm._v(" Save ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }