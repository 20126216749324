import { Node } from "@tiptap/core";

export const CustomVariable = Node.create({
  name: "CustomVariable",
  group: 'inline',
  inline: true,
  content: 'text*',
  code: true,
  renderHTML() {
    return ['custom-variable', {contenteditable: false, class: "data-variable"}, 0]
  },
  parseHTML() {
    return [{
      tag: 'custom-variable'
    }]
  },
})
